<template>
    <div class="button-group">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ButtonGroup'
}
</script>

<style lang="scss">
    @import '@/assets/styles/varibles.scss';

    .button-group {
        .ant-btn:not(:last-child) {
            margin-right: $padding;
        }
    }
</style>
