<template>
    <div class="choose-project-modal-search-panel">
        <a-row>
            <a-col :span="8">
                <a-input v-model="form.projectName" placeholder="请输入项目名称" />
            </a-col>
            <a-col :span="9">
                <div class="choose-project-modal-search-panel__hint">搜索不到该项目，请检查项目名称是否正确！你还可新增项目</div>
            </a-col>
            <a-col :span="5" :offset="2">
                <button-group>
                    <a-button type="primary" @click="handleAdd">新增</a-button>
                    <a-button type="primary" @click="handleSearch">搜索</a-button>
                    <a-button @click="handleReset">重置</a-button>
                </button-group>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import {
    Input as AInput,
    Button as AButton,
    Row as ARow,
    Col as ACol
} from 'ant-design-vue'
import ButtonGroup from '@components/ButtonGroup'

export default {
    name: 'ChooseProjectModalSearchPanel',
    components: {
        AInput,
        AButton,
        ARow,
        ACol,
        ButtonGroup
    },
    data() {
        return {
            form: {
                projectName: ''
            }
        }
    },
    methods: {
        handleAdd() {
            this.$emit('add')
        },
        handleSearch() {
            this.$emit('search', {
                ...this.form
            })
        },
        handleReset() {
            this.form = {}
            this.$emit('reset')
        }
    }
}
</script>

<style lang="scss">
    .choose-project-modal-search-panel {
        &__hint {
            padding-top: 5px;
            padding-left: 20px;
            color: #aaaaaa;
        }
    }
</style>
