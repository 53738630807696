<template>
    <div class="choose-project-modal-content">
        <search-panel @search="handleSearch" @add="handleAdd" @reset="handleReset" />
        <choose-project-table ref="table" />
    </div>
</template>

<script>
import SearchPanel from './SearchPanel'
import ChooseProjectTable from './Table'

export default {
    name: 'ChooseProjectModalContent',
    components: {
        SearchPanel,
        ChooseProjectTable
    },
    methods: {
        handleSearch(params) {
            this.$refs.table.resetSearchParams()
            this.$refs.table.getList(params)
        },
        handleAdd() {
            this.$refs.table.handleAdd()
        },
        handleReset() {
            this.$refs.table.resetSearchParams()
            this.$refs.table.getList({})
        }
    }
}
</script>

<style lang="scss">

</style>
