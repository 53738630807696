<!-- 项目管理 - 项目列表 - 选择项目弹框 -->
<template>
    <custom-modal
        :width="1180"
        title="项目列表"
        :visible="visible"
        :footer="null"
        @on-close="handleClose"
    >
        <modal-content />
    </custom-modal>
</template>

<script>
import CustomModal from '@components/CustomModal'
import ModalContent from './Content'

export default {
    name: 'ChooseProjectModal',
    components: {
        CustomModal,
        ModalContent
    },
    props: {
        visible: Boolean
    },
    methods: {
        handleClose() {
            this.$emit('on-close')
        }
    }
}
</script>
