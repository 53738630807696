import axios from '@service'
import { GET_CHOOSE_PROJECT_LIST } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

// 选择项目列表
export default async function getChooseProjectListService(payload = {}, config = {}) {
    const param = {
        ...GET_CHOOSE_PROJECT_LIST,
        ...config,
        data: payload
    }
    param.url = `${param.url}?current=${payload.current}&size=${payload.size}`

    const { data } = await axios(param)

    if (data.code === HTTP_ERR_SUCCESS) {
        return data.result || {}
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
