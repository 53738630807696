<template>
    <div>
        <custom-table
            class="choose-project-table"
            :row-key="(record, index) => index"
            :loading="loading"
            :columns="columns"
            :data-source="records"
            :pagination="pagination"
            :scroll="scrollConfig"
            @change="handleTableChange"
        >
            <template #action="text, record">
                <a-button type="link" @click="handleSelect(record)">选择</a-button>
            </template>
        </custom-table>
        <custom-mutiple-modal ref="mutiple-modal" @on-ok="() => getList(params)" />
    </div>
</template>

<script>
import { Button as AButton } from 'ant-design-vue'
import CustomTable from '@components/CustomTable'
import CustomMutipleModal from '@weights/CustomMutipleModal'

import tableShowTotal from '@mixins/tableShowTotal'
import eventMixin from '@/mixins/createPage/event'

import { DEFAULT_PAGES_SIZE, EMPTY_VALUE_PLACEHOLDER } from '@config'
import { EVENT_TYPE_NORMAL, INTERFACE_TYPE_MODAL } from '@/constant/enum'

import { empty as emptyFilter } from '@utils'

import getChooseProjectListService from '@/service/project/getChooseProjectListService'

const LIST_PAGE_SIZE = 999
const columns = [
    {
        title: '工程名称',
        dataIndex: 'projectName',
        width: 250
    },
    {
        title: '统一项目编号',
        dataIndex: 'projectCode',
        width: 200,
        customRender: val => emptyFilter(val)
    },
    {
        title: '施工许可证编号',
        dataIndex: 'constructionPermitNo',
        width: 250,
        customRender: val => emptyFilter(val)
    },
    {
        title: '项目地点',
        dataIndex: 'projectDetailAddress',
        width: 250,
        customRender: val => emptyFilter(val)
    },
    {
        title: '施工单位名称',
        dataIndex: 'generalConstructionContractingName',
        width: 250,
        customRender: val => emptyFilter(val)
    },
    {
        title: '建设单位',
        dataIndex: 'constructionUnitName',
        width: 250,
        customRender: val => emptyFilter(val)
    },
    {
        title: '操作',
        fixed: 'right',
        scopedSlots: {
            customRender: 'action'
        }
    }
]

export default {
    name: 'ChooseProjectTable',
    components: {
        AButton,
        CustomTable,
        CustomMutipleModal
    },
    mixins: [tableShowTotal, eventMixin],
    data() {
        return {
            emptyValuePlaceholder: EMPTY_VALUE_PLACEHOLDER,
            size: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            scrollConfig: {
                x: 800,
                y: false
            },

            // 搜索参数
            loading: false,
            records: [],
            columns,
            params: {}
        }
    },
    computed: {
        pagination() {
            const { total, size, current, tableShowTotal } = this
            if (size === LIST_PAGE_SIZE) {
                return false
            }
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: size,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        handleSelect(record = {}) {
            const event = {
                operationType: 10,
                eventType: EVENT_TYPE_NORMAL,
                interfaceType: INTERFACE_TYPE_MODAL,
                permissionCode: 'globalOperation',
                eventCode: 'project_choose_11',
                eventName: '编辑项目'
            }
            this.emitEvent({
                event,
                _params: {
                    id: record.id
                }
            })
        },
        handleAdd(record = {}) {
            const event = {
                operationType: 10,
                eventType: EVENT_TYPE_NORMAL,
                interfaceType: INTERFACE_TYPE_MODAL,
                permissionCode: 'globalOperation',
                eventCode: 'project_10',
                eventName: '新建项目'
            }
            this.emitEvent({
                event,
                _params: {
                    projectCode: record.projectCode
                }
            })
        },
        async getList(params) {
            this.params = params
            const { current, size } = this
            try {
                this.loading = true
                const { records = [], total } = await getChooseProjectListService({
                    current,
                    size,
                    ...params
                })
                this.records = records || []
                this.total = Number(total)
            } catch (e) {
                console.log('-> e', e)
            } finally {
                this.loading = false
            }
        },
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.size = pageSize
            this.getList(this.params)
        },
        resetSearchParams() {
            this.current = 1
        }
    }
}
</script>

<style lang="scss">
    .choose-project-table {
        margin-top: 24px;
    }
</style>
